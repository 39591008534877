define("discourse/plugins/discourse-legal-tools/discourse/initializers/legal-edits", ["exports", "discourse/lib/plugin-api", "discourse/lib/export-csv"], function (_exports, _pluginApi, _exportCsv) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'legal-edits',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.12', api => {
        api.modifyClass('controller:user-activity', {
          actions: {
            exportUserArchive() {
              const extendedUserDownload = this.siteSettings.legal_extended_user_download;
              if (extendedUserDownload) {
                bootbox.confirm(I18n.t("user.download_archive.confirm_all"), I18n.t("no_value"), I18n.t("yes_value"), confirmed => confirmed ? (0, _exportCsv.exportUserArchive)() : null);
              } else {
                this._super();
              }
            }
          }
        });
      });
    }
  };
});